var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                {
                  staticStyle: { "border-right": "1px solid #e8e8e8" },
                  attrs: { span: 4 },
                },
                [
                  _c("dept-tree", {
                    ref: "deptTree",
                    attrs: { deptOptions: _vm.deptOptions },
                    on: { select: _vm.clickDeptNode },
                  }),
                ],
                1
              ),
              _c(
                "a-col",
                {
                  staticStyle: { "padding-left": "10px" },
                  attrs: { span: 20 },
                },
                [
                  _c(
                    "div",
                    { staticClass: "table-page-search-wrapper" },
                    [
                      _c(
                        "a-form",
                        { attrs: { layout: "inline" } },
                        [
                          _c(
                            "a-row",
                            { attrs: { gutter: 48 } },
                            [
                              _c(
                                "a-col",
                                { attrs: { md: 8, sm: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: "设备编号",
                                        prop: "deviceId",
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          placeholder: "请输入设备编号",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.deviceId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "deviceId",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.deviceId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { md: 8, sm: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: "Sim卡号",
                                        prop: "simNo",
                                      },
                                    },
                                    [
                                      _c("a-input", {
                                        attrs: {
                                          placeholder: "请输入卡号",
                                          "allow-clear": "",
                                        },
                                        model: {
                                          value: _vm.queryParam.simNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.queryParam,
                                              "simNo",
                                              $$v
                                            )
                                          },
                                          expression: "queryParam.simNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { md: 8, sm: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: "是否虚拟设备",
                                        prop: "virtualDevice",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            allowClear: "",
                                            placeholder: "请选择是否虚拟设备",
                                          },
                                          model: {
                                            value: _vm.queryParam.virtualDevice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "virtualDevice",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "queryParam.virtualDevice",
                                          },
                                        },
                                        _vm._l(
                                          _vm.virtualDeviceTypeOptions,
                                          function (d) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: d.dictValue,
                                                attrs: { value: d.dictValue },
                                              },
                                              [_vm._v(_vm._s(d.dictLabel))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "a-col",
                                { attrs: { md: 8, sm: 24 } },
                                [
                                  _c(
                                    "a-form-item",
                                    {
                                      attrs: {
                                        label: "设备类型",
                                        prop: "deviceType",
                                      },
                                    },
                                    [
                                      _c(
                                        "a-select",
                                        {
                                          attrs: {
                                            allowClear: "",
                                            placeholder: "请选择设备类型",
                                          },
                                          model: {
                                            value: _vm.queryParam.deviceType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.queryParam,
                                                "deviceType",
                                                $$v
                                              )
                                            },
                                            expression: "queryParam.deviceType",
                                          },
                                        },
                                        _vm._l(
                                          _vm.deviceTypeOptions,
                                          function (d) {
                                            return _c(
                                              "a-select-option",
                                              {
                                                key: d.dictValue,
                                                attrs: { value: d.dictValue },
                                              },
                                              [_vm._v(_vm._s(d.dictLabel))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.advanced
                                ? [
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "IMEI号码",
                                              prop: "imei",
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "请输入IMEI号码",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value: _vm.queryParam.imei,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryParam,
                                                    "imei",
                                                    $$v
                                                  )
                                                },
                                                expression: "queryParam.imei",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "设备型号",
                                              prop: "deviceModel",
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "请输入设备型号",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.queryParam.deviceModel,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryParam,
                                                    "deviceModel",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryParam.deviceModel",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          { attrs: { label: "最近注册时间" } },
                                          [
                                            _c("a-range-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                valueFormat: "YYYY-MM-DD",
                                                format: "YYYY-MM-DD",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value:
                                                  _vm.daterangeRegisterTime,
                                                callback: function ($$v) {
                                                  _vm.daterangeRegisterTime =
                                                    $$v
                                                },
                                                expression:
                                                  "daterangeRegisterTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "安装时间(首次注册时间)",
                                            },
                                          },
                                          [
                                            _c("a-range-picker", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                valueFormat: "YYYY-MM-DD",
                                                format: "YYYY-MM-DD",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value: _vm.daterangeInstallTime,
                                                callback: function ($$v) {
                                                  _vm.daterangeInstallTime = $$v
                                                },
                                                expression:
                                                  "daterangeInstallTime",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-model-item",
                                          {
                                            attrs: {
                                              label: "激活状态",
                                              prop: "activation",
                                            },
                                          },
                                          [
                                            _c(
                                              "a-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择激活状态",
                                                },
                                                model: {
                                                  value:
                                                    _vm.queryParam.activation,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.queryParam,
                                                      "activation",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "queryParam.activation",
                                                },
                                              },
                                              _vm._l(
                                                _vm.activationOptions,
                                                function (item) {
                                                  return _c(
                                                    "a-select-option",
                                                    {
                                                      key: item.dictValue,
                                                      attrs: {
                                                        value: item.dictValue,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.dictLabel)
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-col",
                                      { attrs: { md: 8, sm: 24 } },
                                      [
                                        _c(
                                          "a-form-item",
                                          {
                                            attrs: {
                                              label: "删除标志",
                                              prop: "stateFlag",
                                            },
                                          },
                                          [
                                            _c("a-input", {
                                              attrs: {
                                                placeholder: "请输入删除标志",
                                                "allow-clear": "",
                                              },
                                              model: {
                                                value: _vm.queryParam.stateFlag,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.queryParam,
                                                    "stateFlag",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "queryParam.stateFlag",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                              _c(
                                "a-col",
                                {
                                  attrs: {
                                    md: (!_vm.advanced && 8) || 24,
                                    sm: 24,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "table-page-search-submitButtons",
                                      style:
                                        (_vm.advanced && {
                                          float: "right",
                                          overflow: "hidden",
                                        }) ||
                                        {},
                                    },
                                    [
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: { click: _vm.handleQuery },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "search" },
                                          }),
                                          _vm._v("查询"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          staticStyle: { "margin-left": "8px" },
                                          on: { click: _vm.resetQuery },
                                        },
                                        [
                                          _c("a-icon", {
                                            attrs: { type: "redo" },
                                          }),
                                          _vm._v("重置"),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a",
                                        {
                                          staticStyle: { "margin-left": "8px" },
                                          on: { click: _vm.toggleAdvanced },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.advanced ? "收起" : "展开"
                                              ) +
                                              " "
                                          ),
                                          _c("a-icon", {
                                            attrs: {
                                              type: _vm.advanced
                                                ? "up"
                                                : "down",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-toolbar",
                    {
                      attrs: { loading: _vm.loading, refresh: "", tid: "1" },
                      on: { refreshQuery: _vm.getList },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "table-operations" },
                        [
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:jt808:deviceInfo:add"],
                                  expression: "['tenant:jt808:deviceInfo:add']",
                                },
                              ],
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.createForm.handleAdd()
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "plus" } }),
                              _vm._v("新增 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:jt808:deviceInfo:edit"],
                                  expression:
                                    "['tenant:jt808:deviceInfo:edit']",
                                },
                              ],
                              attrs: { type: "primary", disabled: _vm.single },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.createForm.handleUpdate(
                                    undefined,
                                    _vm.ids
                                  )
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "edit" } }),
                              _vm._v("修改 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:jt808:deviceInfo:remove"],
                                  expression:
                                    "['tenant:jt808:deviceInfo:remove']",
                                },
                              ],
                              attrs: { type: "danger", disabled: _vm.multiple },
                              on: { click: _vm.handleDelete },
                            },
                            [
                              _c("a-icon", { attrs: { type: "delete" } }),
                              _vm._v("删除 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:jt808:deviceInfo:import"],
                                  expression:
                                    "['tenant:jt808:deviceInfo:import']",
                                },
                              ],
                              attrs: { type: "dashed" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.importExcel.importExcelHandleOpen()
                                },
                              },
                            },
                            [
                              _c("a-icon", { attrs: { type: "import" } }),
                              _vm._v("导入 "),
                            ],
                            1
                          ),
                          _c(
                            "a-button",
                            {
                              directives: [
                                {
                                  name: "hasPermi",
                                  rawName: "v-hasPermi",
                                  value: ["tenant:jt808:deviceInfo:export"],
                                  expression:
                                    "['tenant:jt808:deviceInfo:export']",
                                },
                              ],
                              attrs: { type: "primary" },
                              on: { click: _vm.handleExport },
                            },
                            [
                              _c("a-icon", { attrs: { type: "download" } }),
                              _vm._v("导出 "),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("create-form", {
                    ref: "createForm",
                    attrs: {
                      deptOptions: _vm.deptOptions,
                      statusOptions: _vm.statusOptions,
                      deviceTypeOptions: _vm.deviceTypeOptions,
                    },
                    on: { ok: _vm.getList },
                  }),
                  _c("a-table", {
                    attrs: {
                      loading: _vm.loading,
                      size: _vm.tableSize,
                      rowKey: "id",
                      tid: "1",
                      columns: _vm.columns,
                      "data-source": _vm.list,
                      "row-selection": {
                        selectedRowKeys: _vm.selectedRowKeys,
                        onChange: _vm.onSelectChange,
                      },
                      pagination: false,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "deviceType",
                        fn: function (text, record) {
                          return _c("span", {}, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.deviceTypeFormat(record.deviceType)
                                ) +
                                " "
                            ),
                          ])
                        },
                      },
                      {
                        key: "registerTime",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              attrs: {
                                title: _vm.parseTime(record.registerTime),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.parseTime(record.registerTime)) +
                                  " "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "installTime",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              attrs: {
                                title: _vm.parseTime(record.installTime),
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.parseTime(record.installTime)) +
                                  " "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "activation",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              attrs: {
                                title: _vm.activationMap[record.activation],
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.activationMap[record.activation]) +
                                  " "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "status",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {
                              attrs: { title: _vm.statusFormat[record.status] },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.statusFormat(record.status)) +
                                  " "
                              ),
                            ]
                          )
                        },
                      },
                      {
                        key: "operation",
                        fn: function (text, record) {
                          return _c(
                            "span",
                            {},
                            [
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["tenant:jt808:deviceInfo:edit"],
                                    expression:
                                      "['tenant:jt808:deviceInfo:edit']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["tenant:jt808:deviceInfo:edit"],
                                      expression:
                                        "['tenant:jt808:deviceInfo:edit']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.$refs.createForm.handleUpdate(
                                        record,
                                        undefined
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "edit" } }),
                                  _vm._v("修改 "),
                                ],
                                1
                              ),
                              record.deviceType !== 1
                                ? _c("a-divider", {
                                    directives: [
                                      {
                                        name: "hasPermi",
                                        rawName: "v-hasPermi",
                                        value: [
                                          "tenant:jt808:deviceInfo:config",
                                        ],
                                        expression:
                                          "['tenant:jt808:deviceInfo:config']",
                                      },
                                    ],
                                    attrs: { type: "vertical" },
                                  })
                                : _vm._e(),
                              record.deviceType !== 1
                                ? _c(
                                    "a",
                                    {
                                      directives: [
                                        {
                                          name: "hasPermi",
                                          rawName: "v-hasPermi",
                                          value: [
                                            "tenant:jt808:deviceInfo:config",
                                          ],
                                          expression:
                                            "['tenant:jt808:deviceInfo:config']",
                                        },
                                      ],
                                      style:
                                        record.online === 1
                                          ? ""
                                          : "color: #888;cursor: not-allowed",
                                      on: {
                                        click: function ($event) {
                                          record.online === 1 &&
                                            _vm.configEvent(record)
                                        },
                                      },
                                    },
                                    [
                                      _c("a-icon", {
                                        attrs: { type: "info-circle" },
                                      }),
                                      _vm._v("参数设置"),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("a-divider", {
                                directives: [
                                  {
                                    name: "hasPermi",
                                    rawName: "v-hasPermi",
                                    value: ["tenant:jt808:deviceInfo:remove"],
                                    expression:
                                      "['tenant:jt808:deviceInfo:remove']",
                                  },
                                ],
                                attrs: { type: "vertical" },
                              }),
                              _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["tenant:jt808:deviceInfo:remove"],
                                      expression:
                                        "['tenant:jt808:deviceInfo:remove']",
                                    },
                                  ],
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDelete(record)
                                    },
                                  },
                                },
                                [
                                  _c("a-icon", { attrs: { type: "delete" } }),
                                  _vm._v("删除 "),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        },
                      },
                    ]),
                  }),
                  _c("a-pagination", {
                    staticClass: "ant-table-pagination",
                    attrs: {
                      "show-size-changer": "",
                      "show-quick-jumper": "",
                      current: _vm.queryParam.pageNum,
                      total: _vm.total,
                      "page-size": _vm.queryParam.pageSize,
                      showTotal: function (total) {
                        return "共 " + total + " 条"
                      },
                    },
                    on: {
                      showSizeChange: _vm.onShowSizeChange,
                      change: _vm.changeSize,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("import-excel", {
        ref: "importExcel",
        attrs: {
          title: "终端信息管理",
          importTemplate: _vm.importTemplate,
          importData: _vm.importData,
        },
        on: { ok: _vm.getList },
      }),
      _c("config", { ref: "config" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }